import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import StorybookEmbed from "../../../components/StorybookEmbed.jsx";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl="#" codeUrl="#" checklists={checklists.componentSlider.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`A slider component is a user interface element that enables users to select a value from a continuous range by dragging a handle along a track.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "align": "center",
            "width": 250,
            "height": 500,
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/slider/react-native/react-native-slider-1.gif",
            "alt": "slider react native legion"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Example`}</h2>
    <h3>{`Slider with Value useState`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`  import { Slider } from '@legion-crossplatform/ui';
  import { useState } from 'react';

  const [value, setValue] = useState([20]);

  const Slider = () => {
    return (
      <Slider 
        width={200}
        value={value}
        defaultValue={[20]}
        onValueChange={(value) => setValue(value)}
      />
    )
  }

`}</code></pre>
    <h3>{`Vertical Slider`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`  import { Slider } from '@legion-crossplatform/ui';

  const Slider = () => {
    return (
      <Slider 
        orientation="vertical"
        height={200}
      />
    )
  }

`}</code></pre>
    <h3>{`Ranged Slider`}</h3>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "align": "center",
            "width": 250,
            "height": 500,
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/rangedslider/react-native/react-native-ranged-slider-1.gif",
            "alt": "slider react native legion"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`  import { Slider } from '@legion-crossplatform/ui';
  import { useState } from 'react';

  const [value, setValue] = useState([20,50]);

  const Slider = () => {
    return (
      <Slider 
        width={200}
        value={value}
        defaultValue={[20, 50]}
        onValueChange={(value) => setValue(value)}
      />
    )
  }

`}</code></pre>
    <div className="divi" />
    <h2>{`Props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameters`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`dir`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ltr`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`rtl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Controls the side the active track appears on.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`defaultValue`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number[]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Uncontrolled starting value.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`disabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set slider disabled or not.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`minStepsBetweenThumbs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Minimum steps between thumbs.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the slider. For usage with Forms.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`max`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum value.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`min`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Minimum value.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onValueChange`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`(value: number[])=> void`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Callback in which value should be updated`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`orientation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`horizontal`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`vertical`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Direction of the slider.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onSlideEnd`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`(event, value: number) => void`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Called on slide end.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onSlideMove`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`(event, value: number) => void`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Called on slide move.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onSlideStart`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`(event, value: number, target) => void`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Called on slide start.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`SizeTokens`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Size of the slider.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`step`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Minimum thumb move distance.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number[]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Current value of the slider.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      